import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import MainA from '../../layout/Main Admin';
import Loader from '../../components/atoms/Loader';
import Table from '../../components/atoms/Table';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import Input from '../../components/atoms/Input';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import FunctionalDropdown from '../../components/atoms/FunctionalDropdown';
import ToggleSwitch from '../../components/atoms/ToggleSwitch';

import plus from '../../assets/icons/plus.svg';
import edit from '../../assets/icons/edit.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';

import './styles.scss';

const Users = () => {
  const emptyData = {
    userFirstName: '',
    userLastName: '',
    userName: '',
    password: '',
    isActive: false,
    isAdmin: true,
  };
  const [errors, setErrors] = useState({});
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState(emptyData);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const { clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId) {
      getClientUsers(clientId);
    } else {
      getUsers();
    }
  }, [clientId]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const resp = await getRequest('user/GetUsers');
      setUsers(resp.data);
    } catch (error) {
      console.error("Error fetching all users", error);
    } finally {
      setLoading(false);
    }
  };

  const getClientUsers = async (clientId) => {
    setLoading(true);
    try {
      const resp = await getRequest(`user/GetClientUsers/${clientId}`);
      setUsers(resp.data);
    } catch (error) {
      console.error("Error fetching users for client", error);
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    const errorObj = {};
    if (!userData.userFirstName) errorObj.userFirstName = 'Please enter Firstname';
    if (!userData.userLastName) errorObj.userLastName = 'Please enter Lastname';
    if (!userData.userName) errorObj.userName = 'Please enter Username';
    if (!userData.password) errorObj.password = 'Please enter Password';
    setErrors(errorObj);
    return Object.keys(errorObj).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      handleSave();
    }
  };

  const handleUserValidation = () => {
    const errorObj = {};
    if (!userData.userFirstName) errorObj.userFirstName = 'Please enter first name';
    if (!userData.userLastName) errorObj.userLastName = 'Please enter last name';
    if (!userData.userName) errorObj.userName = 'Please enter username';
    if (!userData.password) errorObj.password = 'Please enter password';
    if (userData.isAdmin === '') errorObj.isAdmin = 'Please select user role';

    setErrors(errorObj);
    return Object.keys(errorObj).length === 0;
  };

  const handleChange = (value, type) => {
    setUserData({
      ...userData,
      [type]: value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const postObj = {
      ...userData,
      isAdmin: userData.isAdmin === 'true' || userData.isAdmin === true,
    };

    if (userData.userId) {
      postObj.userId = userData.userId;
    }

    try {
      const response = await postRequest('user/PostUser', postObj);
      if (response.status === 200) {
        setEditing(false);
        setUserData(emptyData);
        setErrors({});
        clientId ? getClientUsers(clientId) : getUsers();
        setConfirmModalOpen(false);
      }
    } catch (error) {
      console.error("Error saving user", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();

    if (handleUserValidation()) {
      try {
        await postRequest('user/PostClientUser', {
          userId: 0,
          userFirstName: userData.userFirstName,
          userLastName: userData.userLastName,
          userName: userData.userName,
          password: userData.password,
          clientId: clientId || userData.clientId,
          isActive: userData.isActive,
          leftUpdatedDateTime: new Date().toISOString(),
          isAdmin: userData.isAdmin,
        });

        NotificationManager.success('User added successfully!');
        setUserModalOpen(false);
        setUserData({
          userId: 0,
          userFirstName: '',
          userLastName: '',
          userName: '',
          password: '',
          clientId: clientId || 0,
          isActive: true,
          leftUpdatedDateTime: new Date().toISOString(),
          isAdmin: false,
        });
        if (clientId) {
          await getClientUsers(clientId);
        }
      } catch (error) {
        console.error("Error adding user", error);
        NotificationManager.error('Failed to add user');
      }
    }
  };

  const handleEdit = (data) => {
    setConfirmModalOpen(true);
    setEditing(true);
    setUserData({
      userId: data.userId,
      userFirstName: data.userFirstName,
      userLastName: data.userLastName,
      userName: data.userName,
      isAdmin: data.isAdmin,
      isActive: data.isActive,
    });
  };

  const handleAddUser = () => {
    setConfirmModalOpen(true);
    setEditing(false);
    setUserData(emptyData);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setUserData(emptyData);
    setErrors({});
    setEditing(false);
  };

  return (
    <MainA active="expense-head-admin" className="b-user-wrapper">
      <Loader loading={loading} />

      {confirmModalOpen && (
        <Modal isOpen={confirmModalOpen} closeModal={handleCloseConfirmationModal}>
          <h4>{clientId ? 'Add User for Client' : 'Add New User'}</h4>
          <form onSubmit={clientId ? handleUserSubmit : handleSubmit}>
            <div className='row'>
              <label htmlFor="userFirstName">First Name:</label>
              <Input
                type="text"
                id="userFirstName"
                value={userData.userFirstName}
                onChange={(e) => handleChange(e.target.value, 'userFirstName')}
                required
              />
              {errors.userFirstName && (
                <ErrorMsg className="v-errorMsg">{errors.userFirstName}</ErrorMsg>
              )}
            </div>
            <div className='row'>
              <label htmlFor="userLastName">Last Name:</label>
              <Input
                type="text"
                id="userLastName"
                value={userData.userLastName}
                onChange={(e) => handleChange(e.target.value, 'userLastName')}
                required
              />
              {errors.userLastName && (
                <ErrorMsg className="v-errorMsg">{errors.userLastName}</ErrorMsg>
              )}
            </div>
            <div className='row'>
              <label htmlFor="userName">Username:</label>
              <Input
                type="text"
                id="userName"
                value={userData.userName}
                onChange={(e) => handleChange(e.target.value, 'userName')}
                required
              />
              {errors.userName && (
                <ErrorMsg className="v-errorMsg">{errors.userName}</ErrorMsg>
              )}
            </div>
            <div className='row'>
              <label htmlFor="password">Password:</label>
              <Input
                type="password"
                id="password"
                value={userData.password}
                onChange={(e) => handleChange(e.target.value, 'password')}
                required
              />
              {errors.password && (
                <ErrorMsg className="v-errorMsg">{errors.password}</ErrorMsg>
              )}
            </div>
            <div className='row-2'>
              <label htmlFor="isAdmin">User Role:</label>
              <FunctionalDropdown
                id="isAdmin"
                value={userData.isAdmin}
                options={[
                  { label: 'Admin', value: true },
                ]}
                onSelect={(option) => handleChange(option.value, 'isAdmin')}
              />
              {errors.isAdmin && <ErrorMsg className="v-errorMsg">{errors.isAdmin}</ErrorMsg>}
            </div>
            <div className='row-2'>
              <label>Active</label>
              <ToggleSwitch
                checked={userData.isActive}
                onChange={(e) => handleChange(e.target.checked, 'isActive')}
              />
            </div>
            <Button className="add-btn" type="submit">
              {clientId ? 'Add User to Client' : 'Add User'}
            </Button>
          </form>
        </Modal>
      )}

      <div className="wrapper">
        <div className="heading-wrapper">
          <h1>Users</h1>
          <div className='clickables'>
            <Button onClick={handleAddUser}>
              <img src={plus} alt="plus icon" /> Add New User
            </Button>
            <Button onClick={() => navigate(-1)}>
              <img src={leftArrow} alt='back arrow' /> Back
            </Button>
          </div>
        </div>

        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users && users.length > 0 ? (
              users.map((user) => (
                <tr key={user.userId}>
                  <td>{user.userFirstName} {user.userLastName}</td>
                  <td>{user.isAdmin ? 'Admin' : ''}</td>
                  <td>{user.isActive ? 'Active' : ''}</td>
                  <td>
                    <a onClick={() => handleEdit(user)} className="edit-btn" type="primary">
                      <img src={edit} alt="edit icon" />
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}><h5 className="centered-text">No User Found</h5></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </MainA>
  );
};

export default Users;
