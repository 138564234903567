import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import Table from '../../components/atoms/Table';
import './styles.scss';
import MainA from '../../layout/Main Admin';
import { getCurrentUser, getDate } from '../../utils/utils';
import Button from '../../components/atoms/Button';
import Loader from '../../components/atoms/Loader';
import CustomDropdown from '../../components/atoms/CustomDropdown';
import { Link } from 'react-router-dom';

const Queries = () => {
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [userType, setUserType] = useState('');
    const [userData, setUserData] = useState({});
    const [queryData, setQueryData] = useState([]);
    const [filteredQueryData, setFilteredQueryData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin');
        }
        if (user) {
            setUserData({
                username: user?.pageName,
                pageId: user?.pageId,
            });
        }

        getData();
    }, []);

    useEffect(() => {
        if (filteredQueryData.length > 0) {
            setCurrentPage(1);
        }
    }, [rowsPerPage, filteredQueryData]);

    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`Client/GetModuleUsageByClient`);
        setQueryData(resp.data);
        setFilteredQueryData(resp.data);
        console.log(resp.data);
        setLoading(false);
    };

    const handleRowsPerPageChange = (option) => {
        setRowsPerPage(option.value);
    };

    return (
        <MainA active="whats-new" className="b-module-usage-wrapper">
            <Loader loading={loading} />
            <div className="wrapper">
                <div className="heading-wrapper">
                    <h1 className="heading-bottom-padding">Module Usage</h1>
                    <div className="clickables">
                        <p>Show</p>
                        <CustomDropdown
                            options={[
                                { label: '10', value: 10 },
                                { label: '20', value: 20 },
                                { label: '50', value: 50 },
                            ]}
                            onSelect={handleRowsPerPageChange}
                            value={{ label: rowsPerPage.toString(), value: rowsPerPage }}
                        />
                        <Link to="/addIns">
                            <Button type="primary">Back</Button>
                        </Link>
                    </div>
                </div>
                <Table
                    pagination={filteredQueryData.length > rowsPerPage}
                    pageSize={rowsPerPage}
                    selectedPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                >
                    <thead>
                        <tr>
                            <th>Module Name</th>
                            <th>Activation Date</th>
                            <th>Deactivated Date</th>
                            <th>Status</th>
                            <th>Service Usage Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredQueryData.map((wnd) => (
                            <tr key={wnd.clientId}>
                                <td>{wnd.moduleName}</td>
                                <td>{getDate(wnd.activationDate)}</td>
                                <td>{getDate(wnd.deactivatedDate)}</td>
                                <td>
                                    <p className={wnd.isActive ? 'active-status' : 'inactive-status'}>
                                        {wnd.isActive ? 'Active' : 'Inactive'}
                                    </p>
                                </td>
                                <td>{wnd.serviceUsageDays}</td>
                            </tr>
                        ))}
                        {filteredQueryData.length === 0 && (
                            <tr>
                                <td colSpan={9}><h5 className="centered-text">No History Found</h5></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </MainA>
    );
};

export default Queries;
