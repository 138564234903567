import React, { useEffect, useRef, useState } from 'react';
import Image from '../../atoms/Image';
import ErrorMsg from '../../atoms/ErrorMsg';
import './styles.scss';
import uploadIcon from "../../../assets/icons/upload.svg";

const Upload = ({ className, value, allowedFiles = [], onChange, resetFile, ...rest }) => {
    const fileInputRef = useRef();

    const [fileName, setFileName] = useState('');
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        fileInputRef.current.value = '';
        setFileName('');
    }, [resetFile]);

    const handelChange = () => {
        const file = fileInputRef.current.files.length > 0 && fileInputRef.current.files[0];

        if (!file) return;

        const fileName = file.name || '';
        const extension = fileName.split('.').pop().toLowerCase();
        const allowed = Array.isArray(allowedFiles) && allowedFiles.indexOf(extension) > -1;
        if (allowed) {
            setFileError('');
            setFileName(fileName);
            onChange(file); 
        } else {
            setFileError(`${extension} file type is not allowed`);
        }
    };

    return (
        <div className='upload-area' {...rest}>
            {!fileName && (
                <div className='image'>
                    <Image className="svg-upload" src={uploadIcon} alt="Upload Icon" />
                </div>
            )}
            {fileName && (
                <div className='file-name-text'>File Name: {fileName}</div>
            )}
            {!fileName && (
                <div className='drop-area'>
                    <div className='drop-text'>
                        Select file to upload
                    </div>
                    {Array.isArray(allowedFiles) && allowedFiles.length > 0 && (
                        <div className='requirement-text'>
                            Only {allowedFiles.join(', ')} file types allowed
                        </div>
                    )}
                </div>
            )}
            {fileError && (
                <ErrorMsg>{fileError}</ErrorMsg>
            )}

            <input
                className={`form-control upload-input ${className}`}
                type="file"
                onChange={handelChange}
                ref={fileInputRef}
                accept={Array.isArray(allowedFiles) ? allowedFiles.map(ext => `.${ext}`).join(',') : ''} // Ensure allowedFiles is an array
            />
        </div>
    );
};

export default Upload;