import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { postRequest, getRequest } from '../../utils/axios';

import MainA from '../../layout/Main Admin';
import Image from "../../components/atoms/Image";
import Button from '../../components/atoms/Button';
import CustomDropdown from '../../components/atoms/CustomDropdown';
import Loader from '../../components/atoms/Loader';

import backArrow from '../../assets/icons/arrow-left.svg';

import './styles.scss';

const CategoryGallery = () => {
    const [state, setState] = useState({
        pageName: '',
        galleryYearMonthData: [],
        galleryByYearMonthData: [],
        selectedImages: [],
        selectedYear: '',
        availableMonths: []
    });

    const [loading, setLoading] = useState(false);
    const [selectedMonthLabel, setSelectedMonthLabel] = useState('');

    const { page } = useParams();
    const navigate = useNavigate();
    const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const isCertificate = queryParams.get('isCertificate') === 'true';
        const isBanner = queryParams.get('isBanner') === 'true';
        const pageNameFromURL = queryParams.get('pageName');

        if (pageNameFromURL) {
            setState((prev) => ({ ...prev, pageName: decodeURIComponent(pageNameFromURL) }));
        }

        fetchGalleryYearMonth();

        if (isBanner) {
            fetchBannerImage(page)
        }
        else if (isCertificate) {
            fetchCertificateImage(page);
        } else {
            fetchSelectedImages(page);
        }
    }, [page]);


    const fetchCertificateImage = async (pageId) => {
        setLoading(true);
        try {
            const resp = await getRequest(`EventCertificate/GetEventCertificateByEventId/${pageId}`);
            const data = resp.data || null;

            if (data) {
                setState((prev) => ({
                    ...prev,
                    selectedImages: [
                        {
                            galleryId: data.galleryId,
                            galleryFilePath: data.galleryFilePath.replace(/\\/g, '/'),
                            galleryFileName: data.galleryFileName,
                            galleryFileTitle: data.galleryFileTitle,
                        },
                    ],
                }));
            }
        } catch (error) {
            NotificationManager.error('Failed to fetch certificate image');
            console.error('Error fetching certificate image:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBannerImage = async (pageId) => {
        setLoading(true);
        try {
            const resp = await getRequest(`EventBanner/GetEventBannerByEventId/${pageId}`);
            const data = resp.data || null;

            if (data) {
                setState((prev) => ({
                    ...prev,
                    selectedImages: [
                        {
                            galleryId: data.galleryId,
                            galleryFilePath: data.galleryFilePath.replace(/\\/g, '/'),
                            galleryFileName: data.galleryFileName,
                            galleryFileTitle: data.galleryFileTitle,
                        },
                    ],
                }));
            }
        } catch (error) {
            NotificationManager.error('Failed to fetch certificate image');
            console.error('Error fetching certificate image:', error);
        } finally {
            setLoading(false);
        }
    };


    const fetchGalleryYearMonth = async () => {
        setLoading(true);
        try {
            const resp = await getRequest('Gallery/GetGalleryYearMonth');
            const data = resp.data || [];
            setState(prev => ({ ...prev, galleryYearMonthData: data }));

            if (data.length > 0) {
                const latestYear = Math.max(...data.map(item => item.year));
                const monthsForLatestYear = data
                    .filter(item => item.year === latestYear)
                    .sort((a, b) => new Date(`01 ${a.month} ${a.year}`) - new Date(`01 ${b.month} ${b.year}`));

                const latestMonthItem = monthsForLatestYear[monthsForLatestYear.length - 1];

                if (latestMonthItem.galleryFilePath) {
                    setState(prev => ({
                        ...prev,
                        selectedYear: latestYear,
                        selectedMonth: latestMonthItem.galleryFilePath,
                        availableMonths: monthsForLatestYear.map(item => ({
                            value: item.galleryFilePath,
                            label: item.month
                        }))
                    }));
                    setSelectedMonthLabel(latestMonthItem.month);
                    fetchGalleryByYearMonth(latestMonthItem.galleryFilePath);
                }
            }
        } catch (error) {
            NotificationManager.error('Failed to fetch gallery year/month data');
        } finally {
            setLoading(false);
        }
    };

    const handleMonthSelection = (selectedOption) => {
        setState(prev => ({
            ...prev,
            selectedMonth: selectedOption.value,
        }));
        setSelectedMonthLabel(selectedOption.label);
        fetchGalleryByYearMonth(selectedOption.value);
    };

    const fetchGalleryByYearMonth = async (galleryFilePath) => {
        setLoading(true);
        try {
            const path = galleryFilePath.replaceAll("\\", "-");
            const resp = await getRequest(`Gallery/GetGalleryByYearMonth/${path}`);
            const galleryData = resp.data.map(rd => ({ ...rd, galleryFilePath }));
            setState(prev => ({ ...prev, galleryByYearMonthData: galleryData || [] }));
        } catch (error) {
            NotificationManager.error('Failed to fetch gallery data by year/month');
        } finally {
            setLoading(false);
        }
    };

    const fetchSelectedImages = async (pageId) => {
        setLoading(true);
        try {
            const resp = await getRequest(`PageGallery/GetPageGallery/${pageId}`);
            const data = resp.data || [];
            setState(prev => ({
                ...prev,
                selectedImages: data.map(img => ({
                    ...img,
                    galleryFilePath: img.galleryFilePath.replace(/\\/g, '/'),
                }))
            }));
        } catch (error) {
            NotificationManager.error('Failed to fetch selected images');
        } finally {
            setLoading(false);
        }
    };

    const handleImageSelection = async (data) => {
        const queryParams = new URLSearchParams(window.location.search);
        const isCertificate = queryParams.get('isCertificate') === 'true';
        const isBanner = queryParams.get('isBanner') === 'true';

        if (isCertificate || isBanner) {
            setState((prev) => ({
                ...prev,
                selectedImages: [data], // Replace selected image with the new one
                galleryByYearMonthData: prev.galleryByYearMonthData.filter((image) => image.galleryId !== data.galleryId)
            }));

            // Post the selected image to the API
            try {
                const payload = {
                    galleryId: data.galleryId,
                    pageId: Number(page),
                };
                const apiEndpoint = isCertificate
                    ? 'EventCertificate/PostEventCertificate'
                    : 'EventBanner/PostEventBanner';

                const response = await postRequest(apiEndpoint, payload);
                if (response.status === 200 || response.status === 201) {
                    NotificationManager.success('Certificate image saved successfully');
                } else {
                    NotificationManager.error('Failed to save certificate image');
                }
            } catch (error) {
                NotificationManager.error('An error occurred while saving the certificate image');
                console.error('Error posting certificate image:', error);
            }
        } else {
            if (!state.selectedImages.some((image) => image.galleryId === data.galleryId)) {
                setState((prev) => ({
                    ...prev,
                    selectedImages: [...prev.selectedImages, data],
                    galleryByYearMonthData: prev.galleryByYearMonthData.filter((image) => image.galleryId !== data.galleryId)
                }));
            }
        }
    };

    const handleImageDeselection = (image) => {
        setState(prev => ({
            ...prev,
            selectedImages: prev.selectedImages.filter(selected => selected.galleryId !== image.galleryId),
            galleryByYearMonthData: [...prev.galleryByYearMonthData, image]
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const isCertificate = queryParams.get('isCertificate') === 'true';
            const isBanner = queryParams.get('isBanner') === 'true';

            if (!isCertificate && !isBanner) {
                const payload = state.selectedImages.map((data) => ({
                    clientId: 0,
                    pageId: Number(page),
                    galleryId: data.galleryId,
                }));
                await postRequest('PageGallery/PostPageGallery', payload);
                NotificationManager.success('Images saved successfully');
            }
        } catch (error) {
            NotificationManager.error('Failed to save images');
            console.error('Error saving images:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleYearSelection = (selectedOption) => {
        const selectedYear = selectedOption.value;
        const monthsForSelectedYear = state.galleryYearMonthData
            .filter(item => item.year === selectedYear)
            .map(item => ({
                value: item.galleryFilePath,
                label: item.month
            }));
        setState(prev => ({
            ...prev,
            selectedYear,
            availableMonths: monthsForSelectedYear
        }));
    };

    const trimText = (text, maxLength) => text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return (
        <MainA active="expense-head-admin" className="b-event-gallery">
            <div className="gallery-wrapper">
                <div className="heading-wrapper">
                    <h1>{state?.pageName}</h1>
                    <div className='clickables'>
                        <CustomDropdown
                            placeholder="Select Year"
                            options={Array.from(new Set(state.galleryYearMonthData.map(item => item.year)))
                                .map(year => ({ value: year, label: year.toString() }))}
                            onSelect={handleYearSelection}
                            value={{ value: state.selectedYear, label: state.selectedYear.toString() }}
                        />
                        <CustomDropdown
                            placeholder="Select Month"
                            options={state.availableMonths}
                            onSelect={handleMonthSelection}
                            value={state.selectedMonth && selectedMonthLabel
                                ? { value: state.selectedMonth, label: selectedMonthLabel }
                                : null}
                            disabled={!state.selectedYear}
                        />

                        <Button onClick={() => navigate(-1)}> <img src={backArrow} alt='back arrow' /> Back</Button>
                    </div>
                </div>

                <div className='wrapper'>
                    <div className="gallery-display">
                        {loading ? (
                            <Loader loading={loading} />
                        ) : (
                            <>
                                <div className="detail">
                                    {state.selectedYear && selectedMonthLabel ? (
                                        <div className="year-section">
                                            <h2>{selectedMonthLabel} {state.selectedYear}</h2>
                                        </div>
                                    ) : (
                                        <div className="year-section">
                                            <h2>Please select a year and month</h2>
                                        </div>
                                    )}
                                </div>

                                <div className='all-images'>
                                    {state.galleryByYearMonthData.length > 0 ? state.galleryByYearMonthData.map(wnd => (
                                        <div
                                            key={wnd.galleryId}
                                            className="image"
                                            onClick={() => handleImageSelection(wnd)}
                                        >
                                            <Image
                                                src={`${imageUrl}/${wnd.galleryFilePath.replace(/\\/g, '/')}${wnd.galleryFileName}`}
                                            />
                                            <div className="file-path-cell" title={wnd.galleryFileTitle}>
                                                {trimText(wnd.galleryFileTitle, 15)}
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='please-select-text'>
                                            Please select Year/Month to view images
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="selected-images">
                        <h2>Selected Images ({state.selectedImages.length})</h2>
                        <div className="selected-list">
                            {state.selectedImages.length > 0 ? (
                                state.selectedImages.map(selectedImage => (
                                    <div
                                        key={selectedImage.galleryId}
                                        className="selected-thumbnail-item"
                                        onClick={() => handleImageDeselection(selectedImage)}
                                    >
                                        <Image
                                            src={`${imageUrl}/${selectedImage.galleryFilePath}${selectedImage.galleryFileName}`}
                                            alt={selectedImage.galleryFileTitle}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p>No images selected.</p>
                            )}
                        </div>
                        {!['true'].includes(new URLSearchParams(window.location.search).get('isCertificate')) &&
                            !['true'].includes(new URLSearchParams(window.location.search).get('isBanner')) && (
                                <Button onClick={handleSubmit}>Save</Button>
                            )}
                    </div>
                </div>
            </div>
        </MainA>
    );
};

export default CategoryGallery;
