import React, { useEffect, useState } from 'react';
import { postRequest, getRequest } from '../../utils/axios';
import { NotificationManager } from 'react-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import MainA from '../../layout/Main Admin';
import Input from '../../components/atoms/Input';
import Upload from '../../components/atoms/Upload';
import Button from '../../components/atoms/Button';
import Anchor from '../../components/atoms/Anchor';
import Modal from '../../components/atoms/Modal';

import pdf from '../../assets/icons/pdf.png';
import doc from '../../assets/icons/document.png';
import xls from '../../assets/icons/xls.png';
import txt from '../../assets/icons/text.png';
import uploadImage from '../../assets/icons/upload_image.svg';
import plus from '../../assets/icons/plus.svg';

import './styles.scss';

const ExpenseHead = () => {
    const [documentsData, setDocumentsData] = useState([]);
    const [documentFileTitle, setDocumentFileTitle] = useState('');
    const [docFile, setDocFile] = useState('');
    const [resetFile, setResetFile] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const docUrl = "https://dynamicproductstorage.blob.core.windows.net/documents";
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({});
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const handleUploadModalOpen = () => setUploadModalOpen(true);
    const handleUploadModalClose = () => setUploadModalOpen(false);


    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        setLoading(true);
        const resp = await getRequest(`Document/GetDocument`);
        setDocumentsData(resp.data);
        setLoading(false);
    };

    const removeDocument = async (documentId) => {
        setLoading(true);
        const resp = await getRequest(`Document/Delete/${documentId}`);
        setDocumentsData(resp.data);
        setLoading(false);
        getCategories();
    };

    const handleTableCheckboxChange = (e, rowdata) => {
        e.preventDefault();
        setConfirmModalOpen(true);
        setConfirmModalData(rowdata);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalData({});
        setConfirmModalOpen(false);
    };

    const handleConfirmModalYes = async () => {
        await removeDocument(confirmModalData.documentId);
        setConfirmModalOpen(false);
    };

    const handleUpload = (file) => {
        setDocFile(file);
        const fileName = file.name;
        if (documentFileTitle === '') {
            setDocumentFileTitle(fileName);
        }
    };

    const handleDocumentFileTitleChange = (e) => {
        setDocumentFileTitle(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('doc', docFile);
        formData.append('documentFileTitle', documentFileTitle);

        try {
            const resp = await postRequest(`Document/PostDocument`, formData);
            setLoading(true);
            if (resp.status === 200) {
                getCategories();
                setDocumentFileTitle('');
                setDocFile('');
                setResetFile(resetFile + 1);
                setLoading(false);
                NotificationManager.success('Success message', 'Document is uploaded Successfully');
            } else {
                console.error('Failed to upload document:', resp);
                setErrors({ submitError: 'Failed to upload document' });
            }
        } catch (error) {
            console.error('Network error:', error);
            setErrors({ submitError: 'Network error occurred' });
        }
    };

    function bytesToSize(bytes) {
        if (bytes < 1024) {
            return `${bytes} B(bytes)`;
        } else if (bytes < 1024 * 1024) {
            return `${(bytes / 1024).toFixed(2)} KB`;
        } else {
            return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        }
    }

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            NotificationManager.success('Copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const getIconByFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return pdf;
            case 'doc':
            case 'docx':
                return doc;
            case 'xls':
            case 'xlsx':
                return xls;
            case 'txt':
                return txt;
            default:
                return doc;
        }
    };
    const trimText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };
    return (
        <MainA active="expense-head-admin" className="b-document-wrapper">
            <Modal isOpen={confirmModalOpen} closeModal={handleCloseConfirmationModal} className="delete-document">
                <h4>Are you sure you want to remove the document?</h4>
                <div className='v-modal-button-wrapper'>
                    <Button onClick={handleConfirmModalYes}>Yes, Remove it</Button>
                    <Button onClick={handleCloseConfirmationModal} type="primary">Cancel</Button>
                </div>

            </Modal>
            <Modal isOpen={uploadModalOpen} closeModal={handleUploadModalClose} className="upload-document">
                <div className="add-document">
                    <img src={uploadImage} alt="upload image" className='icon' />
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <label htmlFor="DocumentFileTitle">Document Name:</label>
                            <Input
                                id="DocumentFileTitle"
                                type="text"
                                value={documentFileTitle}
                                onChange={handleDocumentFileTitleChange}
                                required
                            />
                        </div>
                        <div className='row'>
                            <label htmlFor="doc">Upload Document:</label>
                            <Upload
                                onChange={handleUpload}
                                allowedFiles={['pdf', 'doc', 'xls', 'txt']}
                                resetFile={resetFile}
                            />
                        </div>
                        <Button className="gallery-btn" disabled={loading} type="submit">Submit</Button>
                    </form>
                </div>
            </Modal>

            <div className="document">
                <div className="heading-wrapper">
                    <Button onClick={handleUploadModalOpen}> <img src={plus} alt='add icon' />  Upload Document</Button>
                </div>


                <div className="document-display">
                    <div className="document-list">
                        {documentsData.map((wnd) => (
                            <div
                                key={wnd.documentId}
                                className="document-item"
                            >
                                <div className="selected-document">
                                    <div className='image'>
                                        <img src={getIconByFileType(wnd.documentFileName)} alt={wnd.documentFileTitle} />
                                    </div>
                                    <div className='content'>
                                        <Anchor
                                            to={`${docUrl}/${wnd.documentFilePath ? wnd.documentFilePath.replace(/\\/g, '/') : ''}${wnd.documentFileName || ''}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {trimText(wnd.documentFileTitle, 25)}
                                        </Anchor>
                                        <div className='file' title={`${docUrl}/${wnd.documentFilePath.replace(/\\/g, '/')}${wnd.documentFileName}`}>
                                            <div>
                                                <CopyToClipboard
                                                    className="copy-btn"
                                                    text={`${docUrl}/${wnd.documentFilePath.replace(/\\/g, '/')}${wnd.documentFileName}`}
                                                    onCopy={() => { NotificationManager.success('Copied to clipboard!'); }}
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </CopyToClipboard>
                                            </div>
                                            <div className='full-path truncate-text' title={`${docUrl}/${wnd.documentFilePath.replace(/\\/g, '/')}${wnd.documentFileName}`}>
                                                {`${docUrl}/${wnd.documentFilePath.replace(/\\/g, '/')}${wnd.documentFileName}`}
                                            </div>

                                        </div>
                                        <div className='doc-size' >{bytesToSize(wnd.documentFileSize)}</div>
                                        <Button className="delete-btn" onClick={(e) => handleTableCheckboxChange(e, wnd)}>Delete</Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </MainA>
    );
};

export default ExpenseHead;
