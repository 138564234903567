import React, { useEffect, useState } from 'react';
import { PropTypes } from "prop-types";

import Button from '../Button';

import arrowLeft from '../../../assets/icons/chevron-left.svg';
import arrowRight from '../../../assets/icons/chevron-right.svg';

import './styles.scss';

const Table = ({ 
  className, 
  children, 
  pagination, 
  selectedPage, 
  onPageChange, 
  pageSize, 
  totalRows, // New prop for dynamic total rows
  categoryName, 
  ...rest 
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(selectedPage || 1);

  useEffect(() => {
    if (pagination) {
      calculatePagination();
    }
  }, [totalRows, pageSize]);

  const calculatePagination = () => {
    const pages = Math.ceil(totalRows / pageSize); // Calculate total pages dynamically
    setTotalPages(pages);
    if (page > pages) {
      setPage(1); // Reset to first page if current page exceeds total pages
      onPageChange(1);
    }
  };

  const rowManagement = (page) => {
    const rows = document.querySelectorAll('.v-table tbody tr');
    rows.forEach((r, i) => {
      r.classList.remove('hide');
      if (i >= page * pageSize || i < pageSize * (page - 1)) {
        r.classList.add('hide');
      }
    });
  };

  const handleNext = () => {
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      onPageChange(newPage);
      rowManagement(newPage);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      onPageChange(newPage);
      rowManagement(newPage);
    }
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
    onPageChange(newPage);
    rowManagement(newPage);
  };

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const showingFrom = totalRows === 0 ? 0 : indexOfFirstItem + 1;
  const showingTo = Math.min(indexOfLastItem, totalRows);

  return (
    <div className={`v-table table-responsive ${className}`} {...rest}>
      <table>
        {children}
      </table>
      {pagination && totalPages > 1 && ( // Hide pagination if totalPages is 1 or less
        <div className='b-pagination'>
          <div className='count-show'>{`Showing ${showingFrom} to ${showingTo} of ${totalRows} ${categoryName}`}</div>
          <div className='numbers'>
            <Button disabled={page === 1} type="primary" onClick={handlePrev}>
              <img src={arrowLeft} alt="Previous" />
            </Button>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i + 1}
                className={page === i + 1 ? 'active-page' : 'non-active-page'}
                onClick={() => handlePageClick(i + 1)}
              >
                {i + 1}
              </Button>
            ))}
            <Button disabled={page === totalPages} type="primary" onClick={handleNext}>
              <img src={arrowRight} alt="Next" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  pagination: PropTypes.bool,
  pageSize: PropTypes.number,
  totalRows: PropTypes.number, // New prop
  onPageChange: PropTypes.func,
  categoryName: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  children: <></>,
  pagination: false,
  pageSize: 10,
  totalRows: 0, // Default to 0 rows
  onPageChange: () => {},
  categoryName: 'Items',
};

export default Table;
