import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postRequest, getRequest } from '../../utils/axios';
import { NotificationManager } from 'react-notifications';

import MainA from '../../layout/Main Admin';
import Table from '../../components/atoms/Table';
import Button from '../../components/atoms/Button';
import Upload from '../../components/atoms/Upload';
import Modal from '../../components/atoms/Modal';
import Loader from '../../components/atoms/Loader';
import Input from '../../components/atoms/Input';

import plus from '../../assets/icons/plus.svg';
import backArrow from '../../assets/icons/arrow-left.svg';
import sampleFile from '../../assets/file/sample-file.csv';
import upload from '../../assets/icons/upload_image.svg';

import './styles.scss';

const Participants = () => {
    const [participantData, setParticipantData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [pageName, setPageName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortColumn, setSortColumn] = useState('bibNo');
    const [sortOrder, setSortOrder] = useState('asc');

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageIdFromQuery = queryParams.get('pageId');
        const clientIdFromQuery = queryParams.get('clientId');
        const pageNameFromQuery = queryParams.get('pageName');

        setPageId(Number(pageIdFromQuery));
        setClientId(Number(clientIdFromQuery));
        setPageName(pageNameFromQuery || '');
        if (pageIdFromQuery) {
            fetchParticipants(pageIdFromQuery);
        }
    }, [location.search]);

    const handleSort = (column) => {
        const order = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortOrder(order);

        const sortedData = [...filteredData].sort((a, b) => {
            const aValue = a[column] || '';
            const bValue = b[column] || '';

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredData(sortedData);
    };


    const fetchParticipants = async (pageId) => {
        setLoading(true);
        try {
            const response = await getRequest(`EventParticipant/GetEventParticipants/${pageId}`);
            const data = response.data || [];
            setParticipantData(data);
            setFilteredData(data);
        } catch (error) {
            NotificationManager.error('Failed to fetch participants.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const parsedData = parseCSV(e.target.result);
                uploadCSVData(parsedData);
            } catch (error) {
                NotificationManager.error('Invalid CSV file format.');
                console.error(error);
            }
        };

        reader.onerror = () => {
            NotificationManager.error('Failed to read the file.');
        };

        reader.readAsText(file);
    };

    const parseCSV = (data) => {
        const rows = data.split('\n').filter((row) => row.trim() !== '');
        const headers = rows[0].split(',').map((header) => header.trim());

        return rows.slice(1).map((row) => {
            const values = row.split(',').map((value) => value.trim());
            return headers.reduce((acc, header, index) => {
                if (header === 'BIB Number') acc.bibNo = values[index];
                if (header === 'Ticket_Name') acc.ticketName = values[index];
                if (header === 'Attendee Name') acc.participantName = values[index];
                return acc;
            }, {});
        });
    };

    const uploadCSVData = async (csvData) => {
        const apiData = csvData.map((item) => ({
            bibNo: item.bibNo,
            participantName: item.participantName,
            ticketName: item.ticketName,
            pageId,
            clientId
        }));

        setLoading(true);
        try {
            const response = await postRequest('EventParticipant/PostEventParticipant', apiData);
            if (response.status === 200 || response.status === 201) {
                NotificationManager.success('Data successfully uploaded.');
                fetchParticipants(pageId);
                setIsModalOpen(false);
            } else {
                NotificationManager.error('Failed to upload data.');
            }
        } catch (error) {
            NotificationManager.error('An error occurred while uploading data.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        if (value.trim() === '') {
            setFilteredData(participantData);
        } else {
            setFilteredData(
                participantData.filter((item) =>
                    item.participantName.toLowerCase().includes(value) ||
                    item.bibNo.toLowerCase().includes(value) ||
                    item.ticketName.toLowerCase().includes(value)
                )
            );
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <MainA className="b-participants">
            <div className="event-header">
                <div className='event-name'>
                    <h1>Event Participants</h1>
                    <p>{pageName}</p>
                </div>

                <div className="candidate-buttons">
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />

                    <Button
                        className="modal-open-button"
                        type="button"
                        onClick={openModal}
                    >
                        <img src={plus} alt="plus icon" />
                        Add Participants
                    </Button>

                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                    >
                        <img src={backArrow} alt='left arrow' />
                        Back
                    </Button>
                </div>
            </div>

            {loading ? (
                <Loader loading={loading} />
            ) : (
                <div className="participants-table">
                    <Table pagination={true} pageSize={10}>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('bibNo')}>
                                    Bib Number {sortColumn === 'bibNo' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('ticketName')}>
                                    Ticket Name {sortColumn === 'ticketName' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('participantName')}>
                                    Participant Name {sortColumn === 'participantName' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.bibNo}</td>
                                        <td>{item.ticketName}</td>
                                        <td>{item.participantName}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>
                                        No participant(s) found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
            <Modal
                className="csv-upload-modal"
                isOpen={isModalOpen}
                closeModal={closeModal}
            >
                <div className='upload-image'>
                    <img src={upload} alt='uplod image' />
                </div>
                <h2>Upload CSV File</h2>
                <Upload
                    allowedFiles={['csv']}
                    onChange={handleFileUpload}
                    resetFile={false}
                />
                <a
                    className="download-sample-button"
                    onClick={() => {
                        const link = document.createElement('a');
                        link.href = sampleFile;
                        link.download = 'sample-participants.csv';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    Download Sample CSV
                </a>
            </Modal>
        </MainA>
    );
};

export default Participants;
